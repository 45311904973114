import { useEffect, useRef, useState } from "react";
import useTranslate from "../util/dictionary";
import { Input, Switch, Icon } from '../ui-elements/basic-ui';
import ThemeButton from '../ui-elements/theme-button';
import { localId, stringToBoolean } from "../util/functions";

export function VariantsListings({ product }) {
    const tr = useTranslate();
    return (
        <div style={{ marginLeft: "0.5em", marginTop: "0.25em" }}>
            <p style={{ fontSize: "0.5em" }}>
                {tr("variants_short")}:
            </p>
            <p style={{ fontSize: "0.4em" }}>
                {
                    product.variants.length > 0 ? (
                        product.variants.map((variant) =>
                            <span key={variant._id} style={{ margin: 0, display: "block" }}>{variant.title + (variant.options ? ": " + variant.options.map(opt => opt.name).join(", ") : "")}</span>
                        )
                    ) : (
                        <span style={{ color: "var(--light)" }}>{
                            tr("no_variants")
                        }</span>
                    )
                }
            </p>
        </div>
    );
}

export function VariantsInput({ variants, setVariants, defaultPrice }) {
    const tr = useTranslate();
    const updateData = (variant, option, field, prefunc) => {
        return (e) => {
            setVariants(
                variants.map(v => {
                    if (variant._id === v._id) {
                        /*if(variant.type === "boolean") {
                            v[field] = prefunc ? prefunc(e.target.value) : e.target.value;
                        } else {*/
                            v.options.forEach(o => {
                                if (option._id === o._id) {
                                    o[field] = prefunc ? prefunc(e.target.value) : e.target.value;
                                }
                            });
                        //}
                    }
                    return v;
                })
            )
        };
    };

    const deleteVariant = (id) => {
        setVariants(variants.filter(v => v._id !== id));
    }

    return (
        <div>
            <label>{tr("variants")}</label>
            <br />
            {
                variants.map((variant) => (
                    <Variant
                        key={variant._id}
                        variant={variant}
                        variants={variants}
                        setVariants={setVariants}
                        updateData={updateData}
                        deleteVariant={deleteVariant}
                        defaultPrice={defaultPrice}
                    />
                ))
            }
            <ThemeButton
                icon="plus"
                onClick={
                    //Add variant with two empty options
                    () => setVariants(
                        [...variants, {
                            ...localId(),
                            title: "",
                            options: [
                                {
                                    ...localId(),
                                    description: "",
                                    name: "",
                                    price: 0,
                                },
                                {
                                    ...localId(),
                                    description: "",
                                    name: "",
                                    price: 0,
                                },
                            ]
                        }]
                    )
                }
            />
        </div>
    );
}

function Variant({variant, updateData, deleteVariant, variants, setVariants, defaultPrice}) {
    
    /*const tr = useTranslate();

    const [choice, setChoice] = useState("multiselect"/*variant.type ? variant.type : "boolean");

    useEffect(() => {
        if(choice === "boolean") {
            setVariants(
                variants.map(v => {
                    if(v._id === variant._id) {
                        v.options = null;
                        v.defaultValue = false;
                        v.type = "boolean";
                    }
                    return v;
                }
            ));
        } else if (choice === "multiselect") {
            if (variant.options == null || variant.options.length < 2) {
                setVariants(
                    variants.map(v => {
                        if(v._id === variant._id) {
                            v.options = [
                                {
                                    ...localId(),
                                    description: "", name: "", price: 0,
                                },
                                {   
                                    ...localId(),
                                        description: "", name: "", price: 0,
                                }
                            ];
                            v.defaultValue = null;
                            v.type = "multiselect";
                            v.price = 0;
                        }
                        return v;
                    }
                ));
            }
        }
    }, [choice]);*/
    
    return (
        <div style={{
            border: "5px dotted var(--highlight)",
            marginTop: "1em",
            padding: "1em"
        }}>
            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                <ThemeButton icon="delete" onClick={() => deleteVariant(variant._id)}/>
            </div>
            <Input
                required
                label="variant_title"
                autoComplete=""
                autoFocus
                divStyle={{ flex: 1, marginRight: "0.5em" }}
                value={variant.title}
                onChange={(e) => {
                    setVariants(
                        variants.map(v => {
                            if (v._id === variant._id) {
                                v.title = e.target.value;
                            }
                            return v;
                        })
                    )
                }}
            />
            {/*<Switch
                a={{
                    title: tr("multichoice"),
                    value: "multiselect"
                }}
                b={{
                    title: tr("boolean_choice"),
                    value: "boolean"
                }}
                value={choice}
                onChange={setChoice}
            />}
            {
                choice === "multiselect" ? (*/
                    <>
                        {
                            variant.options?.map((option) => 
                                <Option
                                    key={option._id}
                                    option={option}
                                    updateData={updateData}
                                    variant={variant}
                                    variants={variants}
                                    setVariants={setVariants}
                                    defaultPrice={defaultPrice}
                                />
                            )
                        }
                        <ThemeButton
                            icon="plus"
                            onClick={
                                () => setVariants(
                                    variants.map(v => {
                                        if (variant._id === v._id) {
                                            v.options.push({
                                                ...localId(),
                                                description: "", name: "", price: 0,
                                            });
                                        }
                                        return v;
                                    })
                                )
                            }
                        />
                    </>
                /*) :
                <BooleanSelect
                    variant={variant}
                    updateData={updateData}
                    defaultPrice={defaultPrice}
                    variants={variants}
                    setVariants={setVariants}
                />*/
            }
        </div>
    );
}

function Option({option, updateData, defaultPrice, variant, variants, setVariants}) {
    const priceDifferenceInput = useRef(null);
    const priceInput = useRef(null);

    return (
        <div
            style={{ display: "flex", flexWrap: "wrap", minWidth: "100%", alignItems: "center"}}
        >
            <Input
                type="checkbox"
                value={option.isDefault || false}
                checked={option.isDefault || false}
                label="default"
                onChange={(e) => {
                    variants.forEach(v => {
                        if(v._id === variant._id) {
                            v.options.forEach(o => {
                                if(o._id !== option._id) {
                                    o.isDefault = false;
                                } else {
                                    o.isDefault = e.target.checked;
                                }
                            });
                            return v;
                        }
                    });
                    setVariants([...variants]);
                }}
            />
            <Input
                divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
                required
                label="option_name"
                autoComplete=""
                value={option.name}
                onChange={updateData(variant, option, "name")}
            />
            <PriceInputs
                variant={variant}
                updateData={updateData}
                option={option}
                priceInput={priceInput}
                priceDifferenceInput={priceDifferenceInput}
                defaultPrice={defaultPrice}
            />
            <Input
                divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
                label="description"
                autoComplete=""
                defaultValue={option.description}
                onBlur={updateData(variant, option, "description")}
            />
            <ThemeButton icon="delete" onClick={() => setVariants(
                variants.map(v => {
                    if (variant._id === v._id) {
                        v.options = v.options.filter(o => o._id !== option._id);
                    }
                    return v;
                })
            )}/>
        </div>
    );
}

/*function BooleanSelect({variant, updateData, defaultPrice, variants, setVariants}) {
    const priceDifferenceInput = useRef(null);
    const priceInput = useRef(null);

    const tr = useTranslate();
    
    return (
        <>
            <div style={{
                display: "flex",
                marginTop: "1.5em",
                marginBottom: "1.5em",
            }}>
                <Input
                    type="checkbox"
                    checked={variant.defaultValue ? variant.defaultValue : false}
                    onChange={(e) => {
                        setVariants(
                            variants.map(v => {
                                if (v._id === variant._id) {
                                    v.defaultValue = e.target.checked;
                                }
                                return v;
                            })
                        )
                    }}
                /><span style={{fontSize: "0.75em", marginLeft: "0.5em"}}>{tr("variant_marked_default")}</span>
            </div>
            <PriceInputs
                updateData={updateData}
                variant={variant}
                option={{
                    price: variant.price
                }}
                defaultPrice={defaultPrice}
                priceDifferenceInput={priceDifferenceInput}
                priceInput={priceInput}
            />
        </>
    );
}*/

function PriceInputs({variant, option, updateData, priceInput, priceDifferenceInput, defaultPrice}) {
    return [
        <Input
            key={1}
            divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
            label="price_difference"
            type="number"
            step="0.05"
            autoComplete=""
            defaultValue={(option.price / 100).toFixed(2)}
            onChange={(e) => {
                updateData(variant, option, "price", n => n * 100)(e);
                if(priceInput.current) {
                    priceInput.current.value = (parseFloat(e.target.value) + defaultPrice / 100).toFixed(2);
                }
            }}
            ref={priceDifferenceInput}
        />,
        <Input
            key={2}
            divStyle={{ margin: "0.5em", flex: 3, minWidth: "6em"}}
            label="price"
            type="number"
            step="0.05"
            autoComplete=""
            defaultValue={((defaultPrice + option.price) / 100).toFixed(2)}
            onChange={(e) => {
                updateData(variant, option, "price", n => (n * 100 - defaultPrice))(e);
                if(priceDifferenceInput.current) {
                    priceDifferenceInput.current.value = (parseFloat(e.target.value) - defaultPrice / 100).toFixed(2);
                }
            }}
            ref={priceInput}
        />
    ];
}